import integrations from './containers/Integrations'
import publicApi from './containers/ApiIntegrationContainer'
import magento from './containers/MagentoIntegrationContainer'
import salla from './containers/SallaIntegrationContainer'
import postmen from './containers/PostmenIntegrationContainer'
import shipox from './containers/ShipoxIntegrationContainer'
import shopify from './containers/ShopifyIntegrationContainer'
import storefront from './containers/StorefrontIntegrationContainer'
import wooCommerce from './containers/WooCommerceIntegrationContainer'
import zid from './containers/ZidIntegrationContainer'
import shipRocket from './containers/ShipRocketIntegrationContainer'
import jtExpress from './containers/JTExpressIntegrationContainer'
import iMile from './containers/IMileIntegrationContainer'
import Elite from './containers/EliteIntegrationContainer'
import TCS from './containers/TCSIntegrationContainer'
import LCS from './containers/LCSIntegrationContainer'
import Rider from './containers/RiderIntegrationContainer'
import Trax from './containers/TraxIntegrationContainer'

export default () => [
  ...integrations(),
  ...publicApi(),
  ...magento(),
  ...shopify(),
  ...postmen(),
  ...shipox(),
  ...storefront(),
  ...wooCommerce(),
  ...zid(),
  ...salla(),
  ...shipRocket(),
  ...jtExpress(),
  ...iMile(),
  ...Elite(),
  ...TCS(),
  ...LCS(),
  ...Rider(),
  ...Trax()
]
