import React from 'react'
import { generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import Grid from '@mui/material/Grid'

import { NewButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import { INTEGRATION_TABS } from '~/constants/tabs'

import SkeletonCards from './SkeletonCards'
import IntegrationCard from './IntegrationCard'

const getIntegrationType = (type) => {
  const isSalla = type === 'salla' || type === 'salla_app'
  const isZid = type === 'zid' || type === 'zid_app'
  if (isSalla) {
    return 'salla'
  }

  if (isZid) {
    return 'zid'
  }

  return type
}

function IntegrationList ({ list }) {
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.INTEGRATION}
          parentTitle={NAV.SETTINGS}
          rightButton={(
            <NewButton path={ROUTES.INTEGRATION_TYPE_PATH} />
          )}
        />
      </Header>

      <Content>

        <Grid container={true} spacing={3}>
          {isLoading
            ? (
              <SkeletonCards />
            )
            : results
              .reverse()
              .map((result, index) => {
                const id = prop('id', result)
                const guid = prop('guid', result)
                const name = prop('name', result)
                const description = prop('description', result)
                const status = prop('status', result)
                const type = getIntegrationType(prop('type', result))
                const storefront = type === 'storefront'

                const path = generatePath(ROUTES.INTEGRATION_UPDATE_PATH, {
                  guid: storefront ? id : guid,
                  type,
                  tab: INTEGRATION_TABS.GENERAL
                })

                return (
                  <Grid key={result.id} item={true} xs={12} sm={6} md={4} lg={3}>
                    <IntegrationCard
                      index={index}
                      title={name}
                      path={path}
                      type={type}
                      status={status}
                      description={description}
                    />
                  </Grid>
                )
              })}
        </Grid>
      </Content>
    </Container>
  )
}

IntegrationList.propTypes = {
  list: PropTypes.object.isRequired
}

export default IntegrationList
