import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import { Box, styled } from '@mui/material'
import PropTypes from 'prop-types'
import Link from '@mui/material/Link'
import { FORM_ERROR } from 'final-form'
import { find, includes, pipe, prop, toPairs } from 'ramda'
import { useTranslation } from 'react-i18next'

import { withForm } from '~/components/Form'
import Logo from '~/components/Logo'
import * as ROUTES from '~/constants/routes'
import LinkButton from '~/components/Buttons/LinkButton'

import CompanyInfo from './CompanyInfo'
import PersonalInfo from './PersonalInfo'

import Delivery from '../Images/delivery.svg'
import WarehouseImage from '../Images/warehouse-image.png'

const fields = [
  'email',
  'firstName',
  'lastName',
  'password',
  'phone'
]

const getStepWithErrors = pipe(
  toPairs,
  find(([name]) => includes(name, fields)),
  exist => exist ? PERSONAL_INFO : COMPANY_INFO
)

const PERSONAL_INFO = 'personalInfo'
const COMPANY_INFO = 'companyInfo'

const FormBox = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  paddingTop: theme.spacing(1)
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '70vw',
  height: '100vh',
  zIndex: 10,
  background: '#FFFFFF',
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  }
}))

const BoxImageContainer = styled(Box)(({ theme }) => ({
  width: '30vw',
  height: '100vh',
  zIndex: 9,
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}))

const BoxForm = styled(Box)({
  margin: 'auto',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
})

const BoxFormSize = styled(Box)(({ theme }) => ({
  width: '30vw',
  [theme.breakpoints.down('md')]: {
    width: '50vw',
  },
  [theme.breakpoints.down('sm')]: {
    width: '80vw',
  }
}))

const ImageTopBox = styled(Box)({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  zIndex: 8,
  width: '100%',
  height: '100vh'
})

const ImageTopText = styled(Typography)(({ theme }) => ({
  marginBottom: '24px',
  width: '15vw',
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: {
    fontSize: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px'
  }
}))

const ImageGradientBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  zIndex: 7
})

const GradientBox = styled(Box)({
  width: '100%',
  height: '100vh',
  background: 'rgba(54, 54, 54, 0.95)',
  zIndex: 6
})

const LogoStyled = styled(Logo)({
  width: 160,
  height: 60
})

const LogoBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  padding: '7px',
  display: 'flex',
  justifyContent: 'space-between',
  width: '70vw',
  alignItems: 'center',
  background: '#415C98',
  height: '50.5px',
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  }
}))

const TermsBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '24px',
  width: '70vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  }
}))

const LinkButtonStyled = styled(LinkButton)({
  marginLeft: '10px',
  textTransform: 'unset',
  borderColor: 'white'
})

const DeliveryStyled = styled(Delivery)({
  width: '25vw'
})

function SignUpForm ({ form, isLoading, from }) {
  const [step, setStep] = useState(PERSONAL_INFO)
  const { t } = useTranslation()

  const handleSubmit = formValues => {
    return form.handleSubmit(formValues)
      .then((response) => {
        if (prop(FORM_ERROR, response)) {
          setStep(getStepWithErrors(response))
        }

        return response
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ display: 'flex' }}>
        <BoxContainer>
          <LogoBox>
            <LogoStyled />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" sx={{ color: 'white' }}>{t('Already have an account?')}</Typography>
              <LinkButtonStyled
                state={{ from }}
                href={ROUTES.SIGN_IN}
                variant="outlined"
                type="button"
              >
                {t('Login')}
              </LinkButtonStyled>
            </Box>
          </LogoBox>
          <BoxForm>
            <BoxFormSize>
              <Typography variant="h3">{t('Create Your Storfox Account')}</Typography>
              <Typography variant="subtitle1" sx={{ marginTop: '12px', marginBottom: '36px', fontWeight: '500' }}>
                {t('Sign up to 15 days free trial.')}
              </Typography>
              <FormBox>
                {step === PERSONAL_INFO && (
                  <PersonalInfo
                    submitBtnClassName="sign-up-continue-btn"
                    onNext={() => setStep(COMPANY_INFO)}
                  />
                )}
                {step === COMPANY_INFO && (
                  <CompanyInfo
                    isLoading={isLoading}
                    onBack={() => setStep(PERSONAL_INFO)}
                    submitBtnClassName="sign-up-continue-btn"
                  />
                )}
              </FormBox>
            </BoxFormSize>
          </BoxForm>
          <TermsBox>
            <Link
              target="_blank"
              rel="noreferrer noopener"
              href="https://storfox.com/support/"
              sx={{ margin: '0 16px' }}
            >
              <Typography variant="h6">{t('Support')}</Typography>
            </Link>
            <Link
              target="_blank"
              rel="noreferrer noopener"
              href="https://storfox.com/category/release/"
              sx={{ margin: '0 16px' }}
            >
              <Typography variant="h6">{t('Release')}</Typography>
            </Link>
            <Link
              target="_blank"
              rel="noreferrer noopener"
              href="https://storfox.com/terms-conditions/"
              sx={{ margin: '0 16px' }}
            >
              <Typography variant="h6">{t('Terms')}</Typography>
            </Link>
          </TermsBox>
        </BoxContainer>
        <BoxImageContainer>
          <ImageTopBox>
            <ImageTopText color="white" variant="h2">{t('Delivered Happiness, Every Step of the Way')}</ImageTopText>
            <DeliveryStyled />
          </ImageTopBox>
          <ImageGradientBox>
            <GradientBox />
            <img
              src={WarehouseImage}
              alt="sign-in-image"
              style={{ height: '100vh', position: 'absolute', zIndex: 5, width: '30vw', objectFit: 'cover' }}
            />
          </ImageGradientBox>
        </BoxImageContainer>
      </Box>
    </form>
  )
}

SignUpForm.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  from: PropTypes.object
}

export default withForm(SignUpForm)
