import React from 'react'
import PropTypes from 'prop-types'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import InputBase, { inputBaseClasses } from '@mui/material/InputBase'
import { Field } from 'react-final-form'
import { styled } from '@mui/material'

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  [`& .${inputBaseClasses.input}`]: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    padding: '10px 12px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))

const InputLabelStyled = styled(InputLabel)({
  '&:not(.Mui-focused):not(.MuiFormLabel-filled)': {
    transform: 'translate(14px, 10px) scale(1)',
    paddingLeft: '5px',
    paddingRight: '5px'
  }
})

const SelectLabelStyled = styled(Select)({
  '.MuiInputBase-input': {
    paddingLeft: '15px'
  }
})

function SelectField (props) {
  const {
    label,
    variant,
    options,
    allowNull,
    name,
    disabled,
    onChange,
    className,
    required,
    ...defaultProps
  } = props

  const children = []

  if (options) {
    if (allowNull) {
      children.push(
        <MenuItem key="null" value={null} primaryText="">
          None
        </MenuItem>
      )
    }
  }

  options.forEach(item => children.push(
    <MenuItem key={item.value} value={item.value} data-cy={item.value}>
      {item.name}
    </MenuItem>
  ))

  return (
    <Field name={name}>
      {({ input, meta }) => {
        const handleChange = event => {
          const value = event.target.value
          return Promise.resolve(onChange(value))
            .then(() => input.onChange(value))
        }

        return (
          <FormControl
            variant={variant}
            className={className}
            error={meta.invalid}
            sx={{
              minWidth: 120,
              width: '100%'
            }}
          >
            <InputLabelStyled id={`${name}-label`}>
              {required ? label + ' *' : label}
            </InputLabelStyled>
            <SelectLabelStyled
              labelId={`${name}-label`}
              id={name}
              displayEmpty={true}
              value={input.value}
              disabled={disabled}
              onChange={handleChange}
              onBlur={() => input.onBlur()}
              onFocus={() => input.onFocus()}
              input={<BootstrapInput />}
              {...defaultProps}
            >
              {children}
            </SelectLabelStyled>
            {meta.invalid && (
              <FormHelperText>{meta.submitError || meta.error}</FormHelperText>
            )}
          </FormControl>
        )
      }
      }
    </Field>
  )
}

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,
  options: PropTypes.array,
  allowNull: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  required: PropTypes.bool
}

SelectField.defaultProps = {
  onChange: () => Promise.resolve()
}

export default SelectField
