import React from 'react'
import PropTypes from 'prop-types'
import { always } from 'ramda'

import {
  API,
  IMILE,
  JT_EXPRESS,
  MAGENTO,
  POSTMEN,
  SALLA,
  SHIP_ROCKET,
  SHIPOX,
  SHOPIFY,
  STOREFRONT,
  WOO_COMMERCE,
  ZID,
  ELITE,
  TCS,
  LCS,
  RIDER,
  TRAX
} from '~/constants/integrationTypes'

import ShipoxSvg from './svg/integration/shipox.svg'
import StorefrontSvg from './svg/integration/storefront.svg'
import ShopifyIcon from './svg/integration/shopify.svg'
import WooCommerceIcon from './svg/integration/woocommerce.svg'
import ApiIcon from './svg/integration/api.svg'
import MagentoIcon from './svg/integration/magento.svg'
import ZidIcon from './svg/integration/zid.svg'
import Postmen from './svg/integration/postmen.svg'
import Salla from './svg/integration/salla.svg'
import Shiprocket from './svg/integration/shiprocket.svg'
import JTExpress from './svg/integration/jtexpress.svg'
import iMile from './svg/integration/imile.svg'
import Elite from './svg/integration/elite.svg'
import TCSImage from './svg/integration/tcs.svg'
import LCSImage from './svg/integration/lcs.svg'
import RiderImage from './svg/integration/rider.svg'
import TraxImage from './svg/integration/trax.svg'

const ICONS = {
  [STOREFRONT]: StorefrontSvg,
  [API]: ApiIcon,
  [SHIPOX]: ShipoxSvg,
  [SHOPIFY]: ShopifyIcon,
  [WOO_COMMERCE]: WooCommerceIcon,
  [MAGENTO]: MagentoIcon,
  [ZID]: ZidIcon,
  [POSTMEN]: Postmen,
  [SALLA]: Salla,
  [SHIP_ROCKET]: Shiprocket,
  [JT_EXPRESS]: JTExpress,
  [IMILE]: iMile,
  [ELITE]: Elite,
  [TCS]: TCSImage,
  [LCS]: LCSImage,
  [RIDER]: RiderImage,
  [TRAX]: TraxImage
}

function IntegrationIcon ({ type, isGray, ...props }) {
  const Component = ICONS[type] || always(null)

  return (
    <Component color="primary" {...props} />
  )
}

IntegrationIcon.propTypes = {
  type: PropTypes.string.isRequired,
  isGray: PropTypes.bool
}

export default IntegrationIcon
