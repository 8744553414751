import React from 'react'
import { SingleImageUpload } from 'storfox-image-upload'
import PropTypes from 'prop-types'

import { useImageUpload } from '../ImageUpload'

function SingleImageUploadField ({ name, accept }) {
  const { upload } = useImageUpload()

  const onUpload = (data, onProgress) =>
    upload(data, onProgress)
      .then(response => {
        return response['ids'][0]
      })

  return (
    <SingleImageUpload
      name={name}
      onUpload={onUpload}
      accept={accept}
    />
  )
}

SingleImageUploadField.defaultProps = {
  name: 'image'
}

SingleImageUploadField.propTypes = {
  name: PropTypes.string,
  accept: PropTypes.string
}

export default SingleImageUploadField
