import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Link from '@mui/material/Link'
import { prop } from 'ramda'

import Logo from '~/components/Logo/Logo'
import * as ROUTES from '~/constants/routes'
import LinkButton from '~/components/Buttons/LinkButton'
import { useWhiteLabel } from '~/components/WhiteLabel/WhiteLabel'

import SignInForm from './SignInForm'

import Delivery from '../Images/delivery.svg'
import WarehouseImage from '../Images/warehouse-image.png'

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '70vw',
  height: '100vh',
  zIndex: 10,
  background: '#FFFFFF',
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  }
}))

const BoxImageContainer = styled(Box)(({ theme }) => ({
  width: '30vw',
  height: '100vh',
  zIndex: 9,
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}))

const BoxForm = styled(Box)({
  margin: 'auto',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
})

const BoxFormSize = styled(Box)(({ theme }) => ({
  width: '30vw',
  [theme.breakpoints.down('md')]: {
    width: '50vw',
  },
  [theme.breakpoints.down('sm')]: {
    width: '80vw',
  }
}))

const ImageTopBox = styled(Box)({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  zIndex: 8,
  width: '100%',
  height: '100vh'
})

const ImageTopText = styled(Typography)(({ theme }) => ({
  marginBottom: '24px',
  width: '15vw',
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: {
    fontSize: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px'
  }
}))

const ImageGradientBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  zIndex: 7
})

const GradientBox = styled(Box)({
  width: '100%',
  height: '100vh',
  background: 'rgba(54, 54, 54, 0.95)',
  zIndex: 6
})

const LogoStyled = styled(Logo)({
  width: 160,
  height: 60
})

const LogoBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  padding: '7px',
  display: 'flex',
  justifyContent: 'space-between',
  width: '70vw',
  alignItems: 'center',
  background: '#415C98',
  height: '50.5px',
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  }
}))

const TermsBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '24px',
  width: '70vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  }
}))

const LinkButtonStyled = styled(LinkButton)({
  marginLeft: '10px',
  textTransform: 'unset',
  borderColor: 'white'
})

const DeliveryStyled = styled(Delivery)({
  width: '25vw'
})

function SignIn ({ isLoading, from, onSubmit, ...props }) {
  const { t } = useTranslation()

  const { domainValues } = useWhiteLabel()
  const companyName = prop('name', domainValues)

  return (
    <Box sx={{ display: 'flex' }}>
      <BoxContainer>
        <LogoBox>
          <LogoStyled />
          {!companyName && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" sx={{ color: 'white' }}>
                {t('Don`t have an account yet?')}
              </Typography>
              <LinkButtonStyled
                href={ROUTES.SIGN_UP}
                variant="outlined"
                type="button"
              >
                {t('Signup')}
              </LinkButtonStyled>
            </Box>
          )}
        </LogoBox>
        <BoxForm>
          <BoxFormSize>
            <Typography variant="h3">{t('Welcome Back!')}</Typography>
            <Typography variant="subtitle1" sx={{ marginTop: '10px' }}>
              {t('Continue to')}{' '}{companyName || 'Storfox'}
            </Typography>
            <Box mt={3}>
              <SignInForm
                isLoading={isLoading}
                onSubmit={onSubmit}
                {...props}
              />
            </Box>
          </BoxFormSize>
        </BoxForm>
        {!companyName && (
          <TermsBox>
            <Link
              target="_blank"
              rel="noreferrer noopener"
              href="https://storfox.com/support/"
              sx={{ margin: '0 16px' }}
            >
              <Typography variant="h6">{t('Support')}</Typography>
            </Link>
            <Link
              target="_blank"
              rel="noreferrer noopener"
              href="https://storfox.com/category/release/"
              sx={{ margin: '0 16px' }}
            >
              <Typography variant="h6">{t('Release')}</Typography>
            </Link>
            <Link
              target="_blank"
              rel="noreferrer noopener"
              href="https://storfox.com/terms-conditions/"
              sx={{ margin: '0 16px' }}
            >
              <Typography variant="h6">{t('Terms')}</Typography>
            </Link>
          </TermsBox>
        )}
      </BoxContainer>
      <BoxImageContainer>
        <ImageTopBox>
          <ImageTopText color="white" variant="h3">{t('Delivered Happiness, Every Step of the Way')}</ImageTopText>
          <DeliveryStyled />
        </ImageTopBox>
        <ImageGradientBox>
          <GradientBox />
          <img
            src={WarehouseImage}
            alt="sign-in-image"
            style={{ height: '100vh', position: 'absolute', zIndex: 5, width: '30vw', objectFit: 'cover' }}
          />
        </ImageGradientBox>
      </BoxImageContainer>
    </Box>
  )
}

SignIn.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  from: PropTypes.object,
}

export default SignIn
