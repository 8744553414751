// ROOTS
export const DASHBOARD = 'Dashboard'
export const REPORTS = 'Reports'
export const PURCHASES = 'Purchases'
export const FULFILLMENT = 'Fulfillment'
export const CROSS_DOCKS = 'Cross-Docking'
export const CATALOG = 'Catalog'
export const SALES = 'Sales'
export const STOCK = 'Stock Control'
export const WAREHOUSE = 'Warehouse'
export const SETTINGS = 'Settings'
export const PROFILE = 'Profile'

// DASHBOARD
export const OVERVIEW = 'Overview'
export const TEAM_METRICS = 'Team metrics'

// REPORT
export const STOCK_AGING_REPORT = 'Stock Aging Report'
export const SALES_MARGIN_REPORT = 'Sales Margin Report'
export const TPL_BILLING = '3PL Billing'
export const SALES_OVERVIEW = 'Sales Overview'
export const SALE_ORDER_REPORT = 'Sale Order Report'
export const PURCHASE_REPORT = 'Purchase Receive Report'
export const STORAGE_REPORT = 'Storage Report'
export const INVENTORY_REPORT = 'Inventory Report'
export const UNIT_HISTORY = 'Unit History Report'
export const SHIPMENT_REPORT = 'Shipment Report'
export const UNIT_REPORT = 'Unit Report'
export const ORDER_UNITS_REPORT = 'Sale Order Units Report'
export const RETAILER_UNITS_REPORT = 'Retailer Units Report'
export const LOCATION_UNITS_REPORT = 'Location Wise Units Report'

// CATALOG
export const PRODUCTS = 'Products'
export const BUNDLES = 'Bundles'
export const VARIANTS = 'Variants'
export const CATEGORIES = 'Categories'
export const BRANDS = 'Brands'
export const UNITS = 'Units'

// PURCHASES
export const PURCHASE_ORDER = 'Purchase Orders'
export const SUPPLIERS = 'Suppliers'
export const SUPPLIER_RETURNS = 'Supplier Returns'

// FULFILLMENT
export const SALE_ORDERS = 'Sale Orders'
export const CUSTOMERS = 'Customers'
export const PICKLISTS = 'Pickings'
export const PACKING = 'Packings'
export const SHIPMENTS = 'Shipments'
export const SALE_RETURNS = 'Sale Returns'
export const INVOICES = 'Invoices'

// CROSS DOCKS
export const BUCKETS = 'Buckets'
export const LOT_SHEETS = 'Lot Sheets'
export const CROSS_SHIPMENTS = 'Cross Shipments'

// STOCK
export const RECEIVE = 'Receive'
export const ADJUSTMENT = 'Adjustment'
export const PUTAWAY = 'Putaway'
export const V1_PUTAWAY = 'Putaway v1'
export const V2_PUTAWAY = 'Putaway'
export const TRANSFER = 'Stock Transfer'
export const LISTING = 'Listing'
export const MOVEMENT = 'Movement'
export const STOCK_COUNT = 'Stock Count'
export const INVENTORY_LOG = 'Inventory Log'
export const REORDER = 'Reorder'

// GENERAL
export const WAREHOUSES = 'Warehouses'
export const AREAS = 'Areas'
export const ZONES = 'Zones'
export const LOCATIONS = 'Locations'
export const CONTAINER = 'Container'

// SETTINGS
export const COMPANIES = 'Companies'
export const ROLES = 'Roles'
export const ACCOUNT = 'Account'
export const BILLING = 'Billing'
export const INTEGRATION = 'Integrations'
export const USERS = 'Users'
export const CONFIGURATION = 'Configuration'
export const CONDITION = 'Product conditions'
export const TAX = 'Taxes'
export const BARCODE = 'Barcode'
export const ERROR = 'Error'
