// STOREFRONT
export const STOREFRONT = 'storefront'
export const SHOPIFY = 'shopify'
export const WOO_COMMERCE = 'woocommerce'
export const MAGENTO = 'magento'
export const SALLA = 'salla'

export const STOREFRONT_INTEGRATIONS = [STOREFRONT, SHOPIFY, WOO_COMMERCE, MAGENTO, SALLA]

// SHIPMENT
export const SHIPOX = 'shipox'
export const ZID = 'zid'
export const SHIP_ROCKET = 'shiprocket'
export const JT_EXPRESS = 'jnt'
export const IMILE = 'imile'
export const ELITE = 'elite'
export const LCS = 'lcs'
export const TCS = 'tcs'
export const RIDER = 'rider'
export const TRAX = 'trax'

export const SHIPMENT_INTEGRATIONS = [SHIPOX, ZID, SHIP_ROCKET, JT_EXPRESS, IMILE]

// MARKETPLACE_API
export const API = 'api'

// POSTMEN

export const POSTMEN = 'postmen'

export const INTEGRATION_LIST = [
  ...STOREFRONT_INTEGRATIONS,
  ...SHIPMENT_INTEGRATIONS,
  API,
  POSTMEN
]
