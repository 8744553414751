import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useField, useForm } from 'react-final-form'
import { isNil, map, path, pathEq, pathOr, pick, pipe, prop, propOr, reduce, uniqBy } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { useNavigate } from 'react-router-dom'
import { useConfirm } from 'storfox-confirm-dialog'
import { Beforeunload } from 'react-beforeunload'
import equal from 'fast-deep-equal/react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { matchCondition, unescapeBtoa } from '~/utils'
import { withForm } from '~/components/Form'
import PageTitle from '~/components/PageTitle'
import { Button } from '~/components/Buttons'
import FluidContainer, { FluidContent, FluidHeader } from '~/components/FluidContainer'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { useProfile } from '~/components/Profile'
import useMessages from '~/hooks/useMessages'
import DiscardButtonClick from '~/components/Buttons/DiscardButtonClick'
import { CONDITION_PREFIX, DONE } from '~/constants/barcode'
import { useScreenOutline } from '~/components/ScreenOutline'
import useDialog from '~/hooks/useDialog'
import BarcodeField from '~/components/BarcodeField/BarcodeField'
import BarcodeDoneInstruction from '~/components/Instructions/BarcodeDoneInstruction'
import ConditionInstruction from '~/components/Instructions/ConditionInstruction'
import { CardHeader } from '~/components/Cards'

import PurchaseLineItemGroups from './LineItemGroups/PurchaseLineItemGroups'
import PurchaseDetailLineItemGroups from './LineItemGroups/PurchaseDetailLineItemGroups'
import More from './More'

import BarcodeTitle from '../ReceiveBarcoding/BarcodeTitle'
import ConditionTitle from '../ReceiveBarcoding/ConditionTitle'
import VariantPreview from '../ReceivePurchaseOrder/VariantPreview'
import DestinationPreview from '../ReceiveBarcoding/DestinationPreview'
import {
  getActiveItemMergedWithLineItems,
  getConditionCodeWithoutPrefix
} from '../ReceivePurchaseOrder/utils'
import { useDestination } from '../../components/ReceiveBarcoding/LineItemGroups/hooks'
import useScanSerial from '../../hooks/useScanSerial'
import UnitsBarcodePrintDialogue from '../ReceivePurchaseOrder/UnitsBarcodePrintDialogue'

const getConditionPrintPath = pipe(
  map(condition => {
    const barcode = prop('code', condition)
    return { barcode: `${CONDITION_PREFIX}${barcode}` }
  }),
  unescapeBtoa,
  data => `${ROUTES.BARCODE_GENERATOR_PATH}?barcodes=${data}`
)

const getInitialSerial = (unitNumber, conditionCode) => ({
  unitNumber,
  quantity: 1,
  conditionCode
})

const getUnitInActive = (active, unit, conditionCode) => {
  const unitNumber = path(['serial', 'unitNumber'], unit)
  const guid = path(['productVariant', 'guid'], unit)
  const activeGuid = path(['productVariant', 'guid'], active)
  const activeConditionCode = path(['serial', 'conditionCode'], active)
  const activeUnitNumber = path(['serial', 'unitNumber'], active)

  const trackSerial = path(['productVariant', 'trackSerial'], active)
  const activeSerial = path(['serial', 'serialNumber'], active)
  const checkSerial = trackSerial ? !activeSerial : true

  return (
    guid === activeGuid &&
    conditionCode === activeConditionCode &&
    checkSerial &&
    unitNumber === activeUnitNumber
  )
}

const getStockItemByBarcode = (previousLineItems, barcode) => {
  return previousLineItems.find((item) => {
    const barcodeAliases = pathOr([], ['productVariant', 'barcodeAliases'], item)
    return barcodeAliases.find((alias) => alias === barcode)
  })
}

function ReceivePurchaseBarcoding (props) {
  const {
    form,
    pageTitle,
    conditions,
    conditionListLoading,
    onBarcodeValidate,
    variantDetail,
    isLoading,
    detailLoading
  } = props
  const { handleSubmit } = form

  const { profile } = useProfile()
  const { mutators } = useForm()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const navigate = useNavigate()
  const onConfirm = useConfirm()
  const destination = useDestination()
  const { handleSoundedTrigger, handleSoundedErrorTrigger } = useScreenOutline()
  const { open, handleOpen, handleClose } = useDialog()

  const defaultConditionCode = path(['condition', 'code'], profile)

  const barcodeRef = useRef(null)
  const [isBarcodeMode, setIsBarcodeMode] = useState(true)
  const [lineItemsStack, setLineItemsStack] = useState({})
  const [scanLoading, setScanLoading] = useState(false)
  const [detailUnitsOpen, setDetailUnitsOpen] = useState(false)
  const [activeConditionCode, setActiveConditionCode] = useState(defaultConditionCode)

  const barcodeField = useField('barcode')
  const clearBarcodeField = useCallback(() => barcodeField.input.onChange(''), [barcodeField])
  const focusBarcodeField = () => barcodeRef.current.focus()

  const lineItemsField = useField('lineItems')
  const lineItems = lineItemsField.input.value || []

  const detailLineItemsField = useField('detailLineItems')
  const detailLineItems = detailLineItemsField.input.value || []

  const expiryAtField = useField('expiryAt')
  const expiryAtFieldValue = expiryAtField.input.value || []

  const activeLineItemField = useField('activeLineItem')
  const activeLineItem = activeLineItemField.input.value
  const handleActiveLineItemChange = activeLineItemField.input.onChange
  const handleActiveLineItemRemove = useCallback(() => handleActiveLineItemChange(null), [handleActiveLineItemChange])

  const previousLineItemsField = useField('previousValues.lineItems')
  const previousLineItems = previousLineItemsField.input.value || []

  const activeGuid = path(['productVariant', 'guid'], activeLineItem) || 0
  const trackExpiry = path(['productVariant', 'trackExpiry'], activeLineItem)
  const expiresAt = expiryAtFieldValue.find(item => item.guid === activeGuid)

  const conditionPrintPath = getConditionPrintPath(conditions)

  const { scanSerial } = useScanSerial(activeLineItem, 'productVariant')

  const handleDestinationClear = useCallback(destination.handleClear, [])

  useEffect(() => {
    if (!detailLoading) {
      focusBarcodeField()
    }
  }, [detailLoading])

  useEffect(() => {
    if (!isBarcodeMode) {
      handleDestinationClear()
    }
  }, [handleDestinationClear, isBarcodeMode])

  useEffect(() => {
    if (previousLineItems.length) {
      const newLineItemsStack = reduce((acc, item) => {
        const key = path(['productVariant', 'barcode'], item)
        return { ...acc, [key]: item }
      }, {}, previousLineItems)
      setLineItemsStack(newLineItemsStack)
    }
  }, [previousLineItems])

  const shouldAddExpiresAt = useCallback(() => {
    clearBarcodeField()
    handleSoundedErrorTrigger()
    mutators.setFieldData(`expiryAt`, { error: true })
  }, [clearBarcodeField, handleSoundedErrorTrigger, mutators])

  const handleDestinationRemove = () => {
    clearBarcodeField()
    handleDestinationClear()
    setIsBarcodeMode(true)
  }

  const getAsyncScannedLineItem = useCallback(barcode => {
    return onBarcodeValidate(barcode).then((results) => {
      const variant = path(['data', 'result'], results)
      if (variant) {
        const key = prop('barcode', variant)
        setLineItemsStack({ ...lineItemsStack, [key]: { productVariant: variant } })

        return { productVariant: variant, serial: getInitialSerial(barcode, activeConditionCode) }
      }

      return null
    })
  }, [activeConditionCode, lineItemsStack, onBarcodeValidate])

  const handleDone = useCallback(event => {
    if (trackExpiry && !expiresAt) {
      shouldAddExpiresAt()
      return null
    }
    mutators.setFieldData(`expiryAt`, { error: false })

    if (!isLoading) {
      if (isBarcodeMode) {
        setIsBarcodeMode(false)
        event.preventDefault()
      }

      if (activeLineItem) {
        const newLineItems = getActiveItemMergedWithLineItems({ activeLineItem, lineItems })
        lineItemsField.input.onChange(newLineItems)
        handleActiveLineItemRemove()
        event.preventDefault()
      }

      if (!isBarcodeMode) {
        setIsBarcodeMode(true)
        handleSubmit().then(() => {
          form.form.reset()
        })
      }

      clearBarcodeField()
    }
  }, [
    form,
    activeLineItem,
    clearBarcodeField,
    expiresAt,
    handleActiveLineItemRemove,
    handleSubmit,
    isBarcodeMode,
    isLoading,
    lineItems,
    lineItemsField.input,
    mutators,
    shouldAddExpiresAt,
    trackExpiry
  ])

  const addScannedUnit = useCallback((unit) => {
    const unitInActiveItem = getUnitInActive(activeLineItem, unit, activeConditionCode)
    const isFirstScannedItem = unit && !activeLineItem

    if (!unit) {
      focusBarcodeField()
    }

    if (isFirstScannedItem) {
      handleActiveLineItemChange(unit)
      handleSoundedTrigger()
      clearBarcodeField()
      focusBarcodeField()
    }

    const guid = prop('guid', unit)
    if (activeLineItem && unitInActiveItem) {
      const serial = prop('serial', activeLineItem)
      const newQuantity = prop('quantity', serial) + 1

      const newSerial = { ...serial, quantity: newQuantity }

      const unitLineItem = { guid, ...unit, serial: newSerial }

      handleSoundedTrigger()
      clearBarcodeField()
      return handleActiveLineItemChange(unitLineItem)
    }

    if (trackExpiry && !expiresAt) {
      shouldAddExpiresAt()
      return null
    }
    mutators.setFieldData(`expiryAt`, { error: false })

    if (activeLineItem && !unitInActiveItem) {
      const barcode = path(['serial', 'unitNumber'], unit)
      const otherLineItems = lineItems.filter((item) => path(['serial', 'unitNumber'], item) !== barcode)
      const newLineItems = getActiveItemMergedWithLineItems({ activeLineItem, lineItems: otherLineItems })
      lineItemsField.input.onChange(newLineItems)
      mutators.setFieldData('activeLineItem.serial.serialNumber', { error: null })

      const serial = prop('serial', unit)
      const newSerial = { ...serial, quantity: pathOr(0, ['serial', 'quantity'], unit) }
      const newActiveLineItem = { guid, ...unit, serial: newSerial }

      handleSoundedTrigger()
      clearBarcodeField()
      handleActiveLineItemChange(newActiveLineItem)
    }
  }, [
    activeConditionCode,
    activeLineItem,
    clearBarcodeField,
    expiresAt,
    handleActiveLineItemChange,
    handleSoundedTrigger,
    lineItems,
    lineItemsField.input,
    mutators,
    shouldAddExpiresAt,
    trackExpiry
  ])

  const handleBarcodeScan = useCallback(event => {
    const barcode = pathOr('', ['target', 'value'], event).trim()
    const isCondition = matchCondition(barcode)
    const isDone = barcode === DONE
    const destinationMode = !isBarcodeMode

    const trackSerial = path(['productVariant', 'trackSerial'], activeLineItem)
    const activeSerial = path(['serial', 'serialNumber'], activeLineItem)
    const stockItem = getStockItemByBarcode(previousLineItems, barcode)
    const stockVariant = prop('productVariant', stockItem)
    const stockGuid = prop('guid', stockItem)
    const lineItemItem = lineItems.find(pathEq(['serial', 'unitNumber'], barcode))

    if (trackSerial && !activeSerial) {
      const serial = prop('serial', activeLineItem)
      const newSerial = { ...serial, serialNumber: barcode }
      const unitLineItem = { ...activeLineItem, serial: newSerial }

      clearBarcodeField()
      return handleActiveLineItemChange(unitLineItem)
    }

    if (isDone) {
      return handleDone(event)
    }

    if (destinationMode) {
      return destination.handleChange(event, barcode)
    }

    if (isCondition) {
      const conditionValue = getConditionCodeWithoutPrefix(barcode)
      setActiveConditionCode(conditionValue)
      clearBarcodeField()
      focusBarcodeField()
      return
    }

    if (lineItemItem) {
      event.preventDefault()
      const itemSerial = prop('serial', lineItemItem)
      const serialQuantity = propOr(0, 'quantity', itemSerial)

      const scannedUnit = {
        ...lineItemItem,
        serial: {
          ...itemSerial,
          quantity: serialQuantity + 1
        }
      }

      return addScannedUnit(scannedUnit)
    }

    if (stockItem) {
      event.preventDefault()

      const scannedUnit = {
        ...stockItem,
        guid: stockGuid,
        productVariant: stockVariant,
        serial: getInitialSerial(barcode, activeConditionCode)
      }

      return addScannedUnit(scannedUnit)
    }

    setScanLoading(true)
    getAsyncScannedLineItem(barcode)
      .then(scannedUnit => {
        addScannedUnit(scannedUnit)
        setScanLoading(false)
      })
      .catch(() => {
        setScanLoading(false)
        snackbar({ message: messages.EXIST_FAIL, type: ALTER_ERROR })
        handleSoundedErrorTrigger()
        focusBarcodeField()
        clearBarcodeField()
      })

    event.preventDefault()
  }, [
    previousLineItems,
    lineItems,
    activeConditionCode,
    activeLineItem,
    addScannedUnit,
    clearBarcodeField,
    destination,
    getAsyncScannedLineItem,
    handleActiveLineItemChange,
    handleDone,
    handleSoundedErrorTrigger,
    isBarcodeMode,
    messages.EXIST_FAIL,
    snackbar
  ])

  const handleButtonDone = useCallback(() => {
    if (!scanSerial) {
      if (trackExpiry && !expiresAt) {
        shouldAddExpiresAt()
        return null
      }

      if (isBarcodeMode) {
        setIsBarcodeMode(false)
      }

      if (isBarcodeMode && activeLineItem) {
        const newLineItems = getActiveItemMergedWithLineItems({ activeLineItem, lineItems })
        lineItemsField.input.onChange(newLineItems)
        handleActiveLineItemRemove()
      }

      if (!isBarcodeMode) {
        setIsBarcodeMode(true)
        handleSubmit().then(() => {
          form.form.reset()
        })
      }
    } else {
      handleSoundedErrorTrigger()
      mutators.setFieldData('activeLineItem.serial.serialNumber', { error: 'error' })
    }
  }, [
    form,
    activeLineItem,
    expiresAt,
    handleActiveLineItemRemove,
    handleSoundedErrorTrigger,
    handleSubmit,
    isBarcodeMode,
    lineItems,
    lineItemsField.input,
    mutators,
    scanSerial,
    shouldAddExpiresAt,
    trackExpiry
  ])

  const handleUnitsBarcodePrint = useCallback(({ lineItems }) => {
    const printBarcodeData = []
    for (const item of lineItems) {
      const printQuantity = prop('printQuantity', item)
      const variantBarcode = path(['productVariant', 'barcode'], item)
      for (let i = 0; i < printQuantity; i++) {
        printBarcodeData.push({ barcode: variantBarcode })
      }
    }
    const data = unescapeBtoa(printBarcodeData)
    handleClose()
    window.open(`${ROUTES.BARCODE_GENERATOR_PATH}?barcodes=${data}`, '_blank')
  }, [handleClose])

  const printInitialValues = useMemo(() => {
    const uniqueLineItems = uniqBy(path(['productVariant', 'barcode']), detailLineItems)
    const itemsWithPrintQuantity = uniqueLineItems.map(item => ({ ...item, printQuantity: item.received }))
    return { lineItems: itemsWithPrintQuantity }
  }, [detailLineItems])

  const onPreviewDelete = useCallback(() => {
    const valueBarcode = path(['productVariant', 'barcode'], activeLineItem)
    const valueLineItem = lineItems.filter(pathEq(['productVariant', 'barcode'], valueBarcode))

    if (valueLineItem.length > 0) {
      handleActiveLineItemRemove()
    }

    if (!valueLineItem.length) {
      const previousItem = previousLineItems.filter(pathEq(['productVariant', 'barcode'], valueBarcode))
      if (previousItem.length === 0) {
        handleActiveLineItemRemove()
      } else {
        const oldItemValues = {
          ...previousItem[0],
          productVariant: {
            ...previousItem[0].productVariant,
            ...pick(['width', 'height', 'length', 'weight'], activeLineItem.productVariant)
          }
        }
        const newLineItems = [...lineItems, oldItemValues]
        lineItemsField.input.onChange(newLineItems)
        handleActiveLineItemRemove()
      }
    }
  }, [lineItemsField, handleActiveLineItemRemove, activeLineItem, lineItems, previousLineItems])

  const isUnsaved = !equal(previousLineItems, lineItems)

  const handleDiscard = () => {
    if (isUnsaved) {
      onConfirm({ title: 'Go back?', message: 'Leaving this page will discard unsaved changes.' })
        .agree(() => navigate(-1))
        .disagree()
    } else navigate(-1)
  }

  const handleMoveToActive = useCallback(unit => {
    const unitBarcode = path(['productVariant', 'barcode'], unit)
    const unitSerial = prop('serial', unit)

    const newValues = {
      ...unit,
      serial: {
        conditionCode: activeConditionCode,
        quantity: 1,
        unitNumber: unitBarcode,
        ...unitSerial
      }
    }
    activeLineItemField.input.onChange(newValues)

    const otherLineItems = lineItems.filter((item) => !equal(item, unit))
    if (activeLineItem) {
      const newLineItems = getActiveItemMergedWithLineItems({ activeLineItem, lineItems })
      lineItemsField.input.onChange(newLineItems)
    } else {
      lineItemsField.input.onChange(otherLineItems)
    }
  }, [activeConditionCode, activeLineItem, activeLineItemField.input, lineItems, lineItemsField.input])

  const onEditUnit = useCallback(unit => {
    const unitBarcode = path(['productVariant', 'barcode'], unit)
    const unitSerial = prop('serial', unit)

    if (unitSerial) {
      activeLineItemField.input.onChange(unit)
    } else {
      const newValues = {
        ...unit,
        serial: {
          conditionCode: activeConditionCode,
          quantity: 1,
          unitNumber: unitBarcode,
          ...unitSerial
        }
      }
      activeLineItemField.input.onChange(newValues)
    }

    const otherLineItems = lineItems.filter((item) => !equal(item, unit))
    if (activeLineItem) {
      const newLineItems = getActiveItemMergedWithLineItems({ activeLineItem, lineItems })
      lineItemsField.input.onChange(newLineItems)
    } else {
      lineItemsField.input.onChange(otherLineItems)
    }
  }, [activeConditionCode, activeLineItem, activeLineItemField.input, lineItems, lineItemsField.input])

  const disableEdit = useMemo(() => {
    const trackSerial = path(['productVariant', 'trackSerial'], activeLineItem)

    if (trackSerial) {
      return isNil(path(['serial', 'serialNumber'], activeLineItem))
    }

    return false
  }, [activeLineItem])

  return (
    <FluidContainer>
      <FluidHeader>
        <PageTitle
          pageTitle={pageTitle}
          parentTitle={NAV.RECEIVE}
          rightButton={(
            <>
              <DiscardButtonClick onClick={handleDiscard} />
              <Button
                variant="contained"
                disabled={isLoading}
                type="button"
                data-cy="done"
                onClick={handleButtonDone}
              >
                Done
              </Button>
              <More
                conditionPrintPath={conditionPrintPath}
                conditionPrintPathDisabled={conditionListLoading}
                onPrintUnitsBarcode={handleOpen}
              />
            </>
          )}
        />
      </FluidHeader>
      <FluidContent>
        <Grid container={true} spacing={3} alignItems="flex-end">
          <Grid item={true} lg={5}>
            <Grid container={true}>
              <Grid item={true} xs={12} lg={8}>
                <BarcodeTitle
                  destination={destination}
                  isBarcodeMode={isBarcodeMode}
                  scanSerial={scanSerial}
                />
                <BarcodeField
                  barcodeRef={barcodeRef}
                  disabled={variantDetail.isLoading || isLoading || scanLoading}
                  onEnter={handleBarcodeScan}
                  focusBarcodeField={focusBarcodeField}
                />
              </Grid>
              <Grid item={true} xs={12} lg={4}>
                <ConditionTitle activeConditionCode={activeConditionCode} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12} lg={7}>
            <Box display="flex" justifyContent="flex-end">
              <BarcodeDoneInstruction doneText={DONE} />
              <ConditionInstruction />
            </Box>
          </Grid>
        </Grid>

        <Box mt={3}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <Card>
                <CardHeader title={isBarcodeMode ? 'Scanning' : 'Destination'} />
                {isBarcodeMode ? (
                  <VariantPreview
                    fieldName="activeLineItem.serial"
                    lineItem={activeLineItem}
                    onBarcodeFocus={focusBarcodeField}
                    editDimension={true}
                    onPreviewDelete={onPreviewDelete}
                    lineItemsStack={lineItemsStack}
                  />
                ) : (
                  <DestinationPreview
                    name="destination"
                    onRemove={handleDestinationRemove}
                    onBarcodeFocus={focusBarcodeField}
                  />
                )}
              </Card>
            </Grid>
            <Grid item={true} xs={12}>
              <PurchaseLineItemGroups
                isBarcodeMode={isBarcodeMode}
                detailLoading={detailLoading}
                onEditUnit={onEditUnit}
                disableEdit={disableEdit}
              />
            </Grid>

            <Grid item={true} xs={12}>
              <Box component={Paper}>
                <Box
                  sx={{ display: 'flex', padding: '16px 24px', justifyContent: 'space-between', cursor: 'pointer' }}
                  onClick={() => setDetailUnitsOpen(prev => !prev)}
                >
                  <CardHeader sx={{ padding: 0 }} title="Units from detail" />
                  <KeyboardArrowDownIcon
                    fontSize="large"
                    sx={{
                      transition: '0.3s',
                      transform: detailUnitsOpen ? 'rotate(180deg)' : 'none',
                    }}
                    data-cy="open-detail-units"
                  />
                </Box>
                {detailUnitsOpen && (
                  <PurchaseDetailLineItemGroups
                    isBarcodeMode={isBarcodeMode}
                    detailLoading={detailLoading}
                    onMoveToActive={handleMoveToActive}
                    disableEdit={disableEdit}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        {isUnsaved && <Beforeunload onBeforeunload={event => event.preventDefault()} />}
      </FluidContent>
      {open && (
        <UnitsBarcodePrintDialogue
          onSubmit={handleUnitsBarcodePrint}
          initialValues={printInitialValues}
          open={open}
          onClose={handleClose}
        />
      )}
    </FluidContainer>
  )
}

ReceivePurchaseBarcoding.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  detailLoading: PropTypes.bool.isRequired,
  conditions: PropTypes.array.isRequired,
  conditionListLoading: PropTypes.bool.isRequired,
  variantDetail: PropTypes.object.isRequired,
  pageTitle: PropTypes.string,
  onBarcodeValidate: PropTypes.func.isRequired
}

export default withForm(ReceivePurchaseBarcoding)
