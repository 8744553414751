import { AuthLayout, BlankLayout, CenteredLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import ProfileUpdateContainer from './containers/ProfileUpdateContainer'
import SignInContainer from './containers/SignInContainer'
import SignUpContainer from './containers/SignUpContainer'
import ResetPasswordContainer from './containers/ResetPasswordContainer'
import ForgotPasswordContainer from './containers/ForgotPasswordContainer'
import ConfirmationContainer from './containers/ConfirmationContainer'
import SignUpContractContainer from './containers/SignUpContractContainer'
import SignInContractContainer from './containers/SignInContractContainer'
import IntroductionGuideContainer from './containers/IntroductionGuideContainer'
import Auth2FactorContainer from './containers/Auth2FactorContainer'

export default () => {
  return [
    {
      layout: BlankLayout,
      path: ROUTES.SIGN_IN,
      component: SignInContainer
    },
    {
      layout: BlankLayout,
      path: ROUTES.SIGN_IN_CONTRACT_NUMBER,
      component: SignInContractContainer
    },
    {
      layout: BlankLayout,
      path: ROUTES.SIGN_UP,
      component: SignUpContainer
    },
    {
      layout: BlankLayout,
      path: ROUTES.SIGN_UP_CONTRACT_NUMBER,
      component: SignUpContractContainer
    },
    {
      layout: BlankLayout,
      path: ROUTES.FORGOT_PASSWORD,
      component: ForgotPasswordContainer
    },
    {
      layout: BlankLayout,
      path: ROUTES.RESET_PASSWORD,
      component: ResetPasswordContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.PROFILE_DETAIL_PATH,
      component: ProfileUpdateContainer
    },
    {
      layout: CenteredLayout,
      path: ROUTES.CONFIRMATION,
      component: ConfirmationContainer
    },
    {
      layout: CenteredLayout,
      path: ROUTES.INTRODUCTION_GUIDE,
      component: IntroductionGuideContainer
    },
    {
      layout: BlankLayout,
      path: ROUTES.AUTH_2_FACTOR_PATH,
      component: Auth2FactorContainer
    },
  ]
}
